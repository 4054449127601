var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"feafe3fc01a1f12bc369b3c30f677d969358d34a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { isLocalhost } from '@/configs/config';
import * as Sentry from '@sentry/nextjs';
// const { version } = require('./package.json');

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  ignoreErrors: [],
  release:
    process.env.VERCEL_GIT_COMMIT_SHA ||
    process.env.NEXT_PUBLLIC_VERCEL_GIT_COMMIT_SHA,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: isLocalhost ? 0.0 : 0.4,
  environment:
    process.env.NEXT_PUBLIC_ENV === 'production' ? 'production' : 'development',
  enabled: isLocalhost ? false : true,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
